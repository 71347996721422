import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Contact from "./Images/Carrer.jpg";

const CareerLanding = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/careers?title=${encodeURIComponent(title)}&location=${encodeURIComponent(location)}`);
  };
  return (
    <>
      <div className='containerfluid careerlanding'>
        <h2>Career @ Bestowal</h2>
      </div>
      <div className="container career">
        <div className="search-section text-white">
          <form onSubmit={handleSearch}>
            <div className="row careerrow">
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Job Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Locations"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <button type="submit" className="careerbutton">Search Jobs</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='container aboutcarrier'>
        <div className='carrierrow'>
          <div className='col-md-6'>
          <img src={Contact} alt="Contact" />
          </div>
          <div className="col-md-6">
            <h2>Great Opportunity for Your Inspiring Talent</h2>
            <p>
              Looking to take your career to the next level? Bestowal is
              offering a fantastic opportunity to work with global brands and
              accelerate your career path. If you are a passionate learner with
              solid coding skills and an interest in the latest technologies,
              you'll find a welcoming home at Bestowal.<br /> At Bestowal, we pride
              ourselves on providing an excellent learning environment for our
              consultants, with ample opportunities to continuously update their
              skills and knowledge on the newest and most advanced technologies.
              Our team is dedicated to helping you grow and achieve your full
              potential, both personally and professionally.<br /> With Bestowal,
              you'll be able to work on exciting projects that will challenge
              and inspire you. You'll collaborate with talented and experienced
              professionals who share your passion for technology. Whether
              you're a seasoned expert or just starting out in your career, we
              believe that Bestowal is the perfect place to realize your
              ambitions and achieve your goals.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareerLanding
