import React from "react";
import Solution from "./Images/Engineering.jpeg";
const Engineering = () => {
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={Solution } />
          </div>
          <div className="col-md-8">
            <h1>Bestowal Systems And Services</h1>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid engineering">
        <div className="container native1">
          <div className="row nativerow">
            <div className="col-md-12">
              <h2>Engineering Solutions</h2>
              <p>
              SAP Business One is beneficial for both discrete and process manufacturing. This solution delivers precise, real-time information that growing manufacturing companies require to efficiently manage their daily operations. SAP Business One handles all core engineering product manufacturing, including engineering goods, industrial equipment and machinery, capital goods, and light engineering products such as castings, forgings, and fasteners.
              </p>
            </div>
            <div className="col-md-4">
     
            </div>
          </div>
        </div>
      </div>
      <div className=" container sapibp">
          <div className="sapheading">
            <h2>SAP IBP Alternatives</h2>
            <p>
              In Comparing SAP IBP To Other Supply Chain Planning Tools On The
              Market
            </p>
          </div>
          <div className=" row listgrp">
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>Collaborative Solutions</h2>
                </div>
                <div className="caption1">
                  <p>
                    We work closely with our customers and help them understand
                    the right combination of solutions that might best suit
                    their business configuration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>Expertise</h2>
                </div>
                <div className="caption1">
                  <p>
                    Bestowal has achieved a great deal in SAP implementation and
                    IT services with a focus on customer experience, including
                    cloud solutions, hybrid solutions, e-commerce, analytics and
                    great insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>Experienced Leadership</h2>
                </div>
                <div className="caption1">
                  <p>
                    Our team leader has been working with SAP for over 15 years
                    and is constantly building on our substantial experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>Focus on Reliability and Results</h2>
                </div>
                <div className="caption1">
                  <p>
                    A key area of specialization for us is ensuring on-time
                    delivery of services and developing results-driven IT
                    solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>SAP Transformation Services</h2>
                </div>
                <div className="caption1">
                  <p>
                    We provide end-to-end services in SAP transformation
                    programs, including solution Digitalization,
                    implementations, integration services, support, upgrades,
                    testing, and package evaluation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="engineeringinfo">
                <div className="head1">
                  <h2>Technology Experts</h2>
                </div>
                <div className="caption1">
                  <p>
                    We are well-versed with the integrities of the SAP
                    implementation, IT industry technological advancements thus
                    create futuristic services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Engineering;
