import React from 'react'
import Conclusion from "./Images/Digital-transformation.png";
import oip from "./Images/OIP.jpeg";
const Customer = () => {
  return (
    <>
        <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip} />
          </div>
          <div className="col-md-8">
          <h1>Bestowal Systems And Services</h1>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
          Enhancing Planning and Forecasting for a Leading Semiconductor Company in the USA
        </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
              Our client is a leading semiconductor company based in the USA, known for its cutting-edge technology and innovation in the industry.           
              </p>
               </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP APO (Advanced Planning and Optimization)</li>
                <li>SAP ECC (ERP Central Component)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <p>The client faced several significant challenges:</p>
              <ul>
                <li>
                  <strong>	Decentralized Sales Forecasting</strong>Sales forecasting was decentralized within the S&OP process, leading to inefficiencies.
                </li>
                <li>
                  <strong>	Scenario Planning and Analytics: </strong> The existing scenario planning and analytics capabilities were slow and inadequate.
                </li>
                <li>
                  <strong>High Supply Planning Costs: </strong> The cost of supply planning was very high.
                </li>
                <li>
                  <strong>Poor Alignment: </strong>There was a lack of alignment across planning teams.
                </li>
                <li>
                  <strong>Offline Spreadsheets</strong>Some planning activities relied on offline spreadsheets, reducing efficiency and increasing the risk of errors.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
              Our solution addressed these challenges through a comprehensive implementation of SAP IBP, APO, and ECC:              </p>
              <ul>
                <li>
                  <strong>User-Friendly Dashboards </strong> We developed user-friendly planning views and dashboards to enhance analytics and planning updates.
                </li>
                <li>
                  <strong>Integrated Data Model:</strong>We created a combined data model that incorporated demand, supply, and financial planning, providing a holistic view of the business.
                </li>
                <li>
                  <strong>Real-Time Scenario Planning:  </strong>The solution included real-time capabilities for designing and evaluating what-if scenarios, improving decision-making.
                </li>
                <li>
                  <strong>Inventory Management: </strong> We improved the ability to monitor and manage inventory through real-time alerts.
                </li>
                <li>
                  <strong>Cross-Functional Collaboration: </strong>The implementation facilitated cross-functional collaboration through a single system of record, improving alignment across planning teams.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <p>The implementation of our solution brought substantial benefits to the client:</p>
              <ul>
                <li>
                  <strong>Enhanced Analytics</strong>User-friendly dashboards and planning views improved the efficiency and accuracy of analytics and planning updates.
                </li>
                <li>
                  <strong>Holistic Planning: </strong>An integrated data model for demand, supply, and financial planning provided a comprehensive view of the business, enhancing decision-making.
                </li>
                <li>
                  <strong>Improved Scenario Planning: </strong>Real-time what-if scenario capabilities enabled better planning and responsiveness to changes.
                </li>
                <li>
                  <strong>Better Inventory Management: </strong>Real-time alerts improved the monitoring and management of inventory, reducing the risk of stockouts or overstocking.
                </li>
                <li>
                  <strong>Increased Collaboration: </strong>A single system of record facilitated cross-functional collaboration, improving alignment and reducing planning discrepancies.
                </li>
              </ul>
            </section>
          </div>
          
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6 conclusionsec">
          <h2>Conclusion</h2>
            <p>
            Through strategic implementation of SAP IBP and ECC, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.    
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customer
