import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import CommentSection from "./CommentSection";
import moment from "moment";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
} from "react-share";
import Breadcrumb from "./Breadcrumb";
import Banner from "./Banner";

function RelatedBlogSearch() {
  const [blog, setBlog] = useState(null);
  const { slug } = useParams();
  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [error, setError] = useState(null);

  let profilePicURL = null;

  useEffect(() => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/relatedblogsblogs/${slug}`)
      .then((response) => {
        setBlog(response.data);
        fetchRelatedPosts(response.data.tags);
      })
      .catch((error) => {
        setError("Failed to fetch blog details.");
        console.error("Error fetching blog details:", error);
      });
  }, [slug]);

  useEffect(() => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/comments/${slug}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [slug]);

  const fetchRelatedPosts = (tags) => {
    if (tags && tags.length > 0) {
      axios
        .get(`https://blog-backend-ivfv.onrender.com/blogs?tags=${tags.join(",")}`)
        .then((response) => {
          setRelatedPosts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching related posts:", error);
        });
    }
  };

  const loadComments = () => {
    setCommentsLoaded(true);
  };

  if (!blog) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (blog.author && blog.author.profilePic) {
    profilePicURL = `https://blog-backend-ivfv.onrender.com/${blog.author.profilePic}`;
  }

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="container my-5">
      <Breadcrumb blogTitle={blog.title}  />
      <Banner />

      <div className="row">
        {/* Left Column: Blog Content */}
        <div className=" col-lg-8 col-md-7 col-sm-12 mb-4 detailsLeft">
          <div className="d-flex justify-content-between mt-5">
            <p className="text-muted publish_wrap">
              Published on: {new Date(blog.createdAt).toLocaleDateString()}
            </p>
            <p className="text-muted publish_wrap">
              Category: {blog.category}
            </p>
          </div>          <h1 className="display-5 mt-4">{blog.title}</h1>

          {/* Blog Image */}

          {blog.thumbnail && (
            <img
              src={`https://blog-backend-ivfv.onrender.com/${blog.thumbnail}`}
              alt="Blog"
              className="img-fluid mb-4 rounded"
            />
          )}

          {/* Blog Content */}
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>
          {Array.isArray(blog.tags) && blog.tags.length > 0 && (
            <div className="mt-4">
              <h5>Tags:</h5>
              <div className="d-flex flex-wrap gap-2">
                {blog.tags.map((tag, index) => (
                  <Link
                    key={index}
                    to={`/blogs/category/${tag}`} // Assuming category corresponds to the tag
                    className="badge bg-primary text-decoration-none me-2"
                    style={{ marginBottom: "8px" }}
                  >
                    {tag}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {/* Share Options */}
          <div className="mt-5">
            <h5>Share this post:</h5>
            <div className="d-flex align-items-center gap-3">
              <FacebookShareButton
                url={window.location.href}
                quote={blog.title}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} title={blog.title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={window.location.href}
                title={blog.title}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <RedditShareButton url={window.location.href} title={blog.title}>
                <RedditIcon size={32} round />
              </RedditShareButton>
              <button
                onClick={copyLinkToClipboard}
                className="btn btn-outline-secondary"
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>

        {/* Right Column: Author Info, Share Buttons, and Comments */}
        <div className="col-lg-4 mt-5 col-md-5 col-sm-12 rightWrapper">
          {/* Author Info */}
          <div className="card mb-4">
            <div className="card-body d-flex align-items-center">
              {profilePicURL ? (
                <img
                  src={profilePicURL}
                  alt="Author Profile"
                  className="rounded-circle me-3 mb-3"
                  style={{ width: "60px", height: "60px" }}
                />
              ) : (
                <div
                  className="rounded-circle bg-secondary me-3 mb-3"
                  style={{ width: "60px", height: "60px" }}
                ></div>
              )}
              <div>
                <h6 className="mb-1">{blog.author?.name || "Anonymous"}</h6>
                <p className="text-muted mb-0">
                  {blog.author?.description || "Author description"}
                </p>
              </div>
            </div>
          </div>

          {/* Comments Section */}
          <div className="card text-center commentbox">
            <div className="card-body">
              <h6 className="mb-4">Comments</h6>
              {comments.map((comment) => (
                <div key={comment.id} className="mb-3">
                  <div className="d-flex align-items-start">
                    <div
                      className="rounded-circle bg-secondary me-3"
                      style={{ width: "40px", height: "40px" }}
                    ></div>
                    <div>
                      <h6 className="mb-1">{comment.user || "Anonymous"}</h6>
                      <p className="mb-0">{comment.text}</p>
                    </div>
                  </div>
                </div>
              ))}

              {!commentsLoaded && (
                <button
                  className="btn btn-primary mt-3 w-50"
                  onClick={loadComments}
                >
                  Load Comments
                </button>
              )}

              {commentsLoaded && <CommentSection blogId={slug} />}
            </div>
          </div>
        </div>

        {/* Related Posts */}
        <h3>Related Blogs </h3>
        <div className="blogwrapper row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
  {relatedPosts.length > 0 ? (
    relatedPosts.slice(0, 3).map((blog, id) => (
      <div key={id} className="col-md-4 mb-4">
        <div className="card border-0 shadow-sm">
          {blog.thumbnail && (
            <img
              src={`https://blog-backend-ivfv.onrender.com/${blog.thumbnail}`}
              className="card-img-top"
              alt="blog thumbnail"
            />
          )}
          <div className="card-body">
            <span className="badgee bg-dark mb-2">{blog.category}</span>
            <span className="blogdate">
              {moment(blog.createdAt).format("MMM D, YYYY")}
            </span>
            <h5 className="hovertitle">
              <Link
                to={`/blog/${blog.slug}`}
                className="text-decoration-none text-dark"
              >
                {blog.title}
              </Link>
            </h5>
            <span className="blogdate">{blog.author?.name || "Anonymous"}</span>

          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="col-12">
      <div className="alert alert-warning">No blogs found</div>
    </div>
  )}
</div>

      </div>
    </div>
  );
}

export default RelatedBlogSearch;
