/* eslint-disable no-unused-vars */


import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import logo from "./Images/BestowalHeader.png";

const Header = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const services = [
    { name: "SAP IBP", path: "/sapibp" },
    { name: "SAP S/4HANA Implementation", path: "/saphana" },
    { name: "SAP S/4 Monitoring & Support", path: "/saphana" },
    { name: "SAP Cloud Service", path: "/saphana" },
    { name: "SAP Business one", path: "/saphana" },
    { name: "Node JS Development", path: "/saphana" },
    { name: "React Native Development", path: "/saphana" },
    { name: "Android Development", path: "/saphana" },
    { name: "iOS Development", path: "/saphana" },
    { name: "Java Development", path: "/saphana" },
    { name: "React JS Development", path: "/reactjs" },
    { name: "Dot Net Development", path: "/saphana" },
    { name: "PHP Development", path: "/saphana" },
    { name: "Angular JS Development", path: "/saphana" },
    { name: "Resource Solution", path: "/saphana" },
    { name: "RPA Implementation", path: "/saphana" },
    { name: "Digital Marketing", path: "/saphana" },
    { name: "Search Engine Optimization", path: "/saphana" },
  ];

  const industries = [
    { name: "Engineering Cloud", path: "/engineering" },
    { name: "Electronics Industry", path: "/manufacture" },
    { name: "Chemical Industry", path: "/manufacture" },
    { name: "Supply Industry", path: "/manufacture" },
    { name: "Pharma Industry", path: "/manufacture" },
    { name: "Semiconductor Industry", path: "/manufacture" },
  ];

  const company = [
    { name: "Life at Bestowal", path: "/life" },
    { name: "Career Page", path: "/career" },
    { name: "History of Bestowal", path: "/saphana" },
  ];

  const combinedData = [...services, ...industries, ...company];

  // eslint-disable-next-line no-unused-vars
  const filteredData = combinedData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleLinkClick = () => {
    setIsNavbarCollapsed(true); // Collapse the navbar on link click
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsNavbarCollapsed(false); // Keep the dropdown open when clicking
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowResults(true);
  };

  const handleSelect = () => {
    setSearchQuery("");
    setShowResults(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 992) {
        setIsNavbarCollapsed(true); // Always collapse the navbar on larger screens
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom sticky-top">
      <Link to="/">
        <img src={logo} alt="Bestowal Header Logo" title="Bestowal Logo" className="Logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
        aria-controls="navbarNav"
        aria-expanded={!isNavbarCollapsed}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
        id="navbarNav"
      >
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/about"
            >
              About us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/services"
            >
              Services
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/industrylanding"
            >
              Industries
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/products"
            >
              Products
            </NavLink>
          </li>
          
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              // to="/career"
              to="/careerlanding"
            >
              Careers
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/blogs"
            >
              Blogs
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/contact-us"
            >
              Contact us
            </NavLink>
          </li>
        </ul>
        <div className="contact-button-container">
          <Link className="btn contact-button" onClick={scrollToTop} to="/contact-us" title="Let's Talk">
            Let's Talk
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
