import React from 'react'
import Conclusion from "./Images/Digital-transformation.png";
import oip from "./Images/OIP.jpeg";
const Customer = () => {
  return (
    <>
        <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip} />
          </div>
          <div className="col-md-8">
          <h1>Bestowal Systems And Services</h1>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
          Streamlining Demand Planning for a Leading Pharma Company in the USA          </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
              Our client is a leading pharmaceutical company based in the USA, recognized for its innovative products and extensive market reach.              </p>
            </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP ECC (ERP Central Component)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <p>The client faced several significant challenges:</p>
              <ul>
                <li>
                  <strong>Support Line Integration: </strong>Merging support line changes into a new landscape.
                </li>
                <li>
                  <strong>Multi-Country Rollout:  </strong>  Executing a multi-country rollout following the migration.
                </li>
                <li>
                  <strong>Tight Timeline: </strong> Meeting a shorter project timeline.
                </li>
                <li>
                  <strong>	Diverse Strategies: </strong>Unifying demand planning strategies from formerly independent businesses.
                </li>
                <li>
                  <strong>	Distributed Master Data: </strong>Integrating master data spread across multiple legacy systems.
                </li>
                <li>
                  <strong>	Custom Key Figures: </strong>Managing a large volume of custom key figures needed for planning.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
              Our solution addressed these challenges through a strategic implementation of SAP IBP and ECC
              </p>
              <ul>
                <li>
                  <strong>Proactive Capacity Management: </strong> We enhanced visibility and adopted a proactive approach to reduce reactive capacity expansions and improve STR (Supply to Requirement) delivery performance.
                </li>
                <li>
                  <strong>Streamlined Planning: </strong>The implementation reduced man hours in planning and provided broader supply chain visibility through functionalities like Demand Propagation, Priority-Based Sourcing, Capacity Planning, and What-If Simulations.
                </li>
                <li>
                  <strong>Data Integration: </strong>We focused on robust data integration with back-end systems, ensuring seamless data flow and consistency.
                </li>
                <li>
                  <strong>Rapid Deployment: </strong> The solution was rapidly deployed, delivering significant benefits within a short timeframe.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <p>The implementation of our solution brought substantial benefits to the client:</p>
              <ul>
                <li>
                  <strong>Improved Capacity Management: </strong> Broader visibility and a proactive approach helped reduce reactive capacity expansions and improved STR delivery performance.
                </li>
                <li>
                  <strong>Efficiency Gains: </strong>Reduced man hours in planning and enhanced supply chain visibility led to increased operational efficiency.
                </li>
                <li>
                  <strong>Enhanced Data Integration:</strong>Strong focus on data integration ensured seamless operations and improved data accuracy.
                </li>
                <li>
                  <strong>Quick Benefits: </strong> Rapid deployment facilitated quick realization of benefits, meeting the project's tight timeline.
                </li>
              </ul>
            </section>
          </div>
          
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6 conclusionsec">
          <h2>Conclusion</h2>
            <p>
            Through strategic implementation of SAP IBP and ECC, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.    
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customer
