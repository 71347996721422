/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import vision from "./Images/vision.jpeg";
import mission from "./Images/mission.jpeg";
import value from "./Images/value.jpeg";
import wudza from "./Images/wudza.png";
import ats from "./Images/ats.png";
import Aysmtech from "./Images/aysmtech.png";
import sapture from "./Images/saptrue.png";
import crave from "./Images/crave.png";
import DG from "./Images/daynilgroup.png";
import acnovate from "./Images/acnovate.png";
import medha from "./Images/medha.png";
import ST from "./Images/ST.png";
import rudersoft from "./Images/rudersoft.png";
import TM from "./Images/TM.png";
import crafsol from "./Images/crafsol.png";
import GC from "./Images/gitacloud.png";
import Linksap from "./Images/Linksap.png";
import logix from "./Images/logix.png";
import Oxford from "./Images/Oxford.png";
import Hays from "./Images/Hays.png";
import SiyaTech from "./Images/SiyaTech.png";
import Abt from "./Images/Abt.jpeg";
import About_US from "./Images/About_US.jpg";
import HIC from "./Images/HIC.png";
import Dynpro from "./Images/dynpro.jpg";
import yash from "./Images/yash.png";
import Infosys from "./Images/Infosys.png";
import ContactForm from "./ContactForm.js";

const About = () => {
  const services = [
    { label: "Web Development", value: "web-development" },
    { label: "App Development", value: "app-development" },
    { label: "SEO Services", value: "seo-services" },
    { label: "Cloud Solutions", value: "cloud-solutions" },
  ];

  // Handle form submission success callback
  const handleFormSubmitSuccess = () => {
    console.log("Form successfully submitted on Contact Page");
  };
  return (
    <>
      <div>
        <div className="container-fluid bgcolor">
          <div className="container Aboutus">
            <div className=" row Contents">
              <div className="col-md-12">
        
                {/* <img src={Abt} className="abtus" /> */}
              </div>
            
            </div>
          </div>
        </div>
        <div className="container-fluid bgcolors1">
          <div className="container about">
            <div className="row aboutusrow">
              
              <div className="col-md-4">
                <img src={About_US} />
              </div>
              <div className="col-md-8">
                <h2>About Bestowal</h2>
                <p>
                  <b>Bestowal Systems and Services Pvt. Ltd.</b> offers advanced
                  solutions for complex issues in SAP implementation, web
                  design, and web applications, leveraging our extensive
                  technical and managerial expertise. We focus on delivering
                  superior outcomes using modern technologies, ensuring
                  stakeholders benefit from competitive pricing.
                  <br />
                  As a leading SAP solutions provider, we assist companies of
                  all sizes and industries in achieving optimal performance. Our
                  approach emphasizes efficiency at every stage of SAP module
                  projects. With a team of seasoned industry professionals and a
                  dedicated management team experienced in SAP implementation
                  and IT services, we tailor our solutions to align with our
                  clients' business objectives.We specialize in providing
                  on-demand technological capabilities to meet diverse digital
                  requirements, driven by our commitment to our clients'
                  success. Our dedication to research and development keeps us
                  at the forefront of innovation, enabling us to deliver bespoke
                  software solutions effectively and affordably, while focusing
                  on deadlines and high client satisfaction. Through strategic
                  collaborations with B2B and B2C businesses, we transform their
                  demands and objectives into tangible software products. Our
                  ability to deliver software solutions promptly and reliably is
                  enhanced by our cutting-edge test automation capabilities and
                  infrastructure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bgcolour1">
        <div className="container">
          <div className="row align-items-center Content">
            <div className="col-md-6 order-2 order-md-2 text-start">
              <h2>Our Vision</h2>
              <p>
                By harnessing technology to optimize our clients' and customers'
                business processes, we aim to lead the industry. Our platform
                empowers courageous and ambitious teams, organizations, and
                communities to translate insights into actionable outcomes,
                fostering growth, innovation, implementation, and the
                realization of what's achievable."
              </p>
            </div>
            <div className="col-md-4 order-1 order-md-1">
              <img src={vision} className="abtus" />
            </div>
          </div>
          <div className="row Content">
            <div className="col-md-4 order-1 order-md-2">
              <img src={mission} className="abtus" />
            </div>
            <div className="col-md-6 order-2 order-md-1 text-end">
              <h2>Our Mission</h2>
              <p>
                "We strive for our clients to commend us for our pivotal role in
                enabling them to capture and transform their data through
                technology, driving business excellence. Our mission is centered
                around understanding and fulfilling people's interests, goals,
                and potential. We design our products and technology with a deep
                focus on empathizing with diverse perspectives and meeting
                individual needs. We are committed to equality and amplifying
                voices, particularly those that may otherwise be marginalized or
                overlooked."
              </p>
            </div>
          </div>
          <div className="row Content">
            <div className="col-md-6 order-2 order-md-2 text-start">
              <h2>Our Value</h2>
              <p>
                "As essential to the success of SAP Implementation and IT
                Services as our unified mission, vision, goals, and metrics are,
                our unique values stand out—the defining attributes that shape
                our conduct and drive us toward tangible, enduring results.
                These values are not mere slogans but deeply ingrained
                convictions, universally upheld by all who deliver our services
                to clients and customers with extensive knowledge and skills."
              </p>
            </div>
            <div className="col-md-4 order-1 order-md-1">
              <img src={value} className="abtus" />
            </div>
          </div>
        </div>
      </div>
      <div className="strategy1">
        <div className="container strategy">
          <h2>What Makes us Different</h2>
          <div className=" row listgrp">
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>Collaborative Solutions</h2>
                </div>
                <div className="caption1">
                  <p>
                    We work closely with our customers and help them understand
                    the right combination of solutions that might best suit
                    their business configuration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>Expertise</h2>
                </div>
                <div className="caption1">
                  <p>
                    Bestowal has achieved a great deal in SAP implementation and
                    IT services with a focus on customer experience, including
                    cloud solutions, hybrid solutions, e-commerce, analytics and
                    great insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>Experienced Leadership</h2>
                </div>
                <div className="caption1">
                  <p>
                    Our team leader has been working with SAP for over 15 years
                    and is constantly building on our substantial experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>Focus on Reliability and Results</h2>
                </div>
                <div className="caption1">
                  <p>
                    A key area of specialization for us is ensuring on-time
                    delivery of services and developing results-driven IT
                    solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>SAP Transformation Services</h2>
                </div>
                <div className="caption1">
                  <p>
                    We provide end-to-end services in SAP transformation
                    programs, including solution Digitalization,
                    implementations, integration services, support, upgrades,
                    testing, and package evaluation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info1">
                <div className="head1">
                  <h2>Technology Experts</h2>
                </div>
                <div className="caption1">
                  <p>
                    We are well-versed with the integrities of the SAP
                    implementation, IT industry technological advancements thus
                    create futuristic services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="container client-section">
        <h2 className="client-heading">Our Clients</h2>
        <p className="client-description">
          Empowering Success Stories: Meet Our Valued Clients
        </p>
      </div>
      <div className="container-fluid marquee">
        <marquee behavior="scroll" direction="start" scrollamount="10">
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://linksapjobs.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Linksap}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://www.logix-tech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logix}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.oxfordcorp.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Oxford}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="http://hays.de/" target="_blank" rel="noopener noreferrer">
            <img
              src={Hays}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="http://siatech.asia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SiyaTech}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: "24px" }}
            />
          </a>
        </marquee>
      </div>
      <div className="container-fluid contact-section" id="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ContactForm
                services={services}
                onSubmitSuccess={handleFormSubmitSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
