import React from 'react'
import Image2 from '../src/Images/1733392627156.png'
import './Blog.css'

function Blog() {
  return (
    <div className="blog-container">
      
      <div className="blog-section">
      <h3 >FORECAST CONSUMPTION IN SAP IBP</h3>
        <p>In this blog, we delve into one of the important topic of SAP IBP -</p>
        
        <p className='highlight'>Why forecast consumption is needed?</p>
        <ol className="blog-list">
          <li>If the input key figures customer demand or independent demand include forecasts and sales orders, and if a new sales order is then placed, the forecast needs to be reduced by the sales order quantity. Otherwise, the planning algorithm will consider the demand quantity twice</li>
          <li>Once as the forecast.</li>
          <li>Once as the actual sales order.</li>
        </ol>

        <p className='highlight'>AT WHAT LEVEL DO WE WANT TO CONSUME THE FORECAST?</p>
        <p>
          Generally, we use below sample planning levels depending on business to business.
          <br />
          <span className="code-snippet">WK-PROD-LOC-CUST</span>
          <br />
          OR
          <br />
          <span className="code-snippet">WK-PROD-LOC-CUST-SBU</span> (SALES BUSINESS UNIT)
        </p>
      </div>

      <div className="blog-section">
        <h3>CUSTOM ALERTS IN SAP IBP</h3>
        <img src={Image2} className="blog-image" alt="Custom Alerts Diagram" />
        <p className="highlight">Introduction:</p>
        <p>
        Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts. Instead of creating numerous distinct definitions, it is feasible to centralize the definition of custom alerts, applying different criteria to different properties and time periods. You can apply key figure criteria to various time periods (you can specify a horizon in which an alert is more likely to occur, or is more meaningful), or to calculation level attributes (you can specify that alert has to be generated, for example, for a certain customer, or for a certain location). It gives more flexibility and usability. 
        </p>
        <p className="highlight">Why Custom Alerts Matter:</p>
        <p>Custom alerts are generated by the system and visible to assigned users regarding important or critical supply chain situations. Information contained in the custom alert allows users to analyze and measure the impact of unexpected situations on their business. Users can fine-tune the criteria for alerts to be generated to mitigate issues in the supply chain. Custom alerts are integrated with cases in the SAP Supply Chain Control Tower, which facilitates the tracking and resolution of supply chain problems.</p>
        <p>Alerts and their overview can also be added to dashboards to complement the visualization of different aspects of planning in organization.</p>
        <p className="highlight"> The apps available for custom alerts include the following:</p>
        <ol className="blog-list">
          <li>Define and Subscribe to Custom Alerts</li>
          <li>Monitor Custom Alerts</li>
          <li>Define Custom Alerts Overview</li>
        </ol>
        <p className="highlight">Custom Alerts in control Tower :</p>
        <p>The system generates custom notifications about critical or significant supply chain conditions that are visible to designated people. Custom alerts are integrated with cases in SAP Supply Chain Control Tower, which facilitates the tracking and resolution of supply chain problems. Information contained in the custom alert allows users to analyze and measure the impact of unexpected situations on their business.</p>
      </div>
    </div>
  )
}

export default Blog