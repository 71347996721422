
import React, { useState } from "react";
import Angularimg from "./Images/Angular.png";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import Angular1 from "./Images/Angular1.png";
import Angular2 from "./Images/Angular2.png";
import Angular3 from "./Images/Angular3.png";
import Angular4 from "./Images/Angular4.png";
import Angular5 from "./Images/Angular5.png";
import Angular6 from "./Images/Angular6.png";

const Angular = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {/* angular */}
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={Angularimg} alt="Angular" />
          </div>
          <div className="col-md-8">
            <h1>Angular Development</h1>
          </div>
        </div>
      </div>
      {/* angular development in India */}
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Angular Js Web Development Services India</h2>
          <p>
            As a leading AngularJS development company based in India, Bestowal
            Systems & Services specializes in building fast-loading web
            applications using AngularJS's advanced code-splitting technology.
            Our skilled team of developers leverages AngularJS to create
            scalable, flexible, and secure web and mobile applications. Whether
            you need a social networking platform, e-commerce site, marketplace
            application, or custom widgets and interactive dashboards, our
            AngularJS
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                 development services are tailored to meet your specific
                 requirements.If you're searching for an AngularJS web application development
                firm in Pune, India, contact us today. Our experienced AngularJS
                developers, with over 5 years of expertise, are ready to bring
                your ideas to life.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      {/* Angular Map */}
      <div className="container native">
        <h2>Angular Road Map</h2>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular1} className="angularimg" alt="Angular 1" />
            </span>
            <h3>Fantastic Framework</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular2} className="angularimg" alt="Angular 2" />
            </span>
            <h3>Real-time Application</h3>
          </div>
        </div>
        <div className="row angularframe1">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular3} className="angularimg" alt="Angular 3" />
            </span>
            <h3>Cost-Effective Development</h3>
          </div>
          <div className="angularcontent">
            <img src={Angularimg} alt="Angular" />
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular4} className="angularimg" alt="Angular 4" />
            </span>
            <h3>Two Way Data Binding</h3>
          </div>
        </div>
        <div className="row angularframe">
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular5} className="angularimg" alt="Angular 5" />
            </span>
            <h3>Ease of Synchronization</h3>
          </div>
          <div className="angularcontent">
            <span className="angularspan">
              <img src={Angular6} className="angularimg" alt="Angular 6" />
            </span>
            <h3>REST Friendly</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Angular;
