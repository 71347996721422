import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Career = () => {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5;
  const [totalJobs, setTotalJobs] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const titleFilter = searchParams.get('title') || '';
  const locationFilter = searchParams.get('location') || '';
  const BACKEND_URL = "https://hrms-backend-mmdo.onrender.com/api/jobs";

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const url = new URL(`${BACKEND_URL}/bestowal_jobs`);
        url.searchParams.append('title', titleFilter);
        url.searchParams.append('location', locationFilter);
        url.searchParams.append('page', currentPage);
        url.searchParams.append('limit', jobsPerPage);

        const response = await fetch(url);
        const data = await response.json();
        
        setJobs(Array.isArray(data.jobs) ? data.jobs : []);
        setTotalJobs(data.total || 0);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setJobs([]);
      }
    };

    fetchJobs();
  }, [currentPage, titleFilter, locationFilter]);

  const goBack = () => navigate(-1);

  const handleJobClick = (jobId) => navigate(`/details/${jobId}`);

  return (
    <>
      <div className="container-fluid careers">
        <h1>Career at Bestowal</h1>
      </div>

      <div className="container career">
        <div className="ms-auto">
          <button className="back-button mb-4" onClick={goBack}>
            Back
          </button>
        </div>

        <div className="container jobtable">
          <div className="row jobtablerow">
            <div className="col-md-12">
              {jobs.length > 0 ? (
                <table className="table job-header">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Location</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobs.map((job) => (
                      <tr
                        key={job._id}
                        onClick={() => handleJobClick(job._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{job.title}</td>
                        <td>{job.location}</td>
                        <td>{new Date(job.createdAt).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No jobs available</p>
              )}
            </div>
          </div>
        </div>

        {jobs.length > 0 && (
          <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Job pagination">
              <ul className="pagination mb-5">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                  >
                    «
                  </button>
                </li>
                {[...Array(Math.ceil(totalJobs / jobsPerPage)).keys()].map((page) => (
                  <li
                    key={page + 1}
                    className={`page-item ${currentPage === page + 1 ? "active" : ""}`}
                  >
                    <button 
                      className="page-link"
                      onClick={() => setCurrentPage(page + 1)}
                    >
                      {page + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(p => Math.min(p + 1, Math.ceil(totalJobs / jobsPerPage)))}
                    disabled={currentPage >= Math.ceil(totalJobs / jobsPerPage)}
                  >
                    »
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};

export default Career;