import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";

const Details = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    axios
      .get(`https://hrms-backend-mmdo.onrender.com/api/jobs/jobs/${jobId}`)
      .then((response) => {
        setJobData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  }, [jobId]);

  if (loading) {
    return <div>Loading job details...</div>;
  }

  return (

      
      <div className="container career">
      <button className="back-button mb-5" onClick={goBack}>Back</button>
      <h1>{jobData?.title}</h1>
      {jobData?.description && <><h3><strong>Description:</strong></h3><p>{jobData.description}</p></>}
      {jobData?.location && <h3><strong>Location:</strong> {jobData.location}</h3>}
      <h3><strong>Required Experience:</strong> {jobData?.Experience} years</h3>
      {jobData?.jobType && <h3><strong>Job Type:</strong> {jobData.jobType}</h3>}
      {jobData?.Shift && <h3><strong>Shift:</strong> {jobData.Shift}</h3>}
      {jobData?.workMode && <h3><strong>Work Mode:</strong> {jobData.workMode}</h3>}
      {jobData?.duration && <h3><strong>Duration:</strong> {jobData.duration}</h3>}
      {jobData?.NoofOpening && <h3><strong>No of Openings:</strong> {jobData.NoofOpening}</h3>}
      {jobData?.interviewRounds && <h3><strong>Interview Rounds:</strong> {jobData.interviewRounds}</h3>}
      {jobData?.Technologies?.length > 0 && <h3><strong>Technologies:</strong> {jobData.Technologies.join(", ")}</h3>}
      {jobData?.Skills?.length > 0 && <h3><strong>Skills:</strong> {jobData.Skills.join(", ")}</h3>}
      <div className="applybutton">
        <Link className="nav-link" to="/Login">
          <button className="mb-5">Apply Now</button>
        </Link>
      </div>
    </div>  

  );
};

export default Details;
